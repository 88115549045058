@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Roboto&family=Open+Sans&display=swap');

@font-face {
  font-family: ashkan;
  src: url(/public/ashkan.ttf);
}
* {
  font-family: ashkan;
}
.keyboard {
  margin-top: -30px;
  margin-bottom: -10px;
}
.phantom {
  display: block;
  padding: 20px;
  height: 0px;
  width: 0px;
}
body {
  position:relative;
}

body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #f5f5f5;
  border-radius: 10px;

}
body::-webkit-scrollbar {
  width: 15px;
  background-color: #f5f5f5;
}
body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #fff;
  background-image: -webkit-linear-gradient(
    top,
    #4158d0 0%,
    #c850c0 46%,
    #ffcc70 100%
  );
}

.footer {
  background-color: #f8f8f8;
  border-top: 1px solid #e7e7e7;
  text-align: center;
  position: fixed;
  left: 0;
  bottom: 0;
  height: 25px;
  width: 100%;
}
.cell-fill-animation {
  animation: onTypeCell linear;
  animation-duration: 0.35s;
}

.cell-hover:hover {
  transform: scale(1.2);
  cursor: pointer;
  transition: all 0.2s linear;
}

.cell-reveal {
  animation-duration: 0.35s;
  animation-timing-function: linear;
  animation-fill-mode: backwards;
}

.cell-reveal.absent {
  animation-name: revealAbsentCharCell;
}

.cell-reveal.correct {
  animation-name: revealCorrectCharCell;
}

.cell-reveal.present {
  animation-name: revealPresentCharCell;
}

.cell-reveal > .letter-container {
  animation: offsetLetterFlip 0.35s linear;
  animation-fill-mode: backwards;
}

@keyframes revealAbsentCharCell {
  0% {
    transform: rotateX(0deg);
    background-color: theme('colors.slate.400');
    border-color: theme('colors.slate.400');
  }
  100% {
    transform: rotateX(180deg);
    background-color: theme('colors.slate.400');
    border-color: theme('colors.slate.400');
  }
}

@keyframes revealCorrectCharCell {
  0% {
    transform: rotateX(0deg);
    background-color: theme('colors.slate.400');
    border-color: theme('colors.slate.400');
  }
  100% {
    transform: rotateX(180deg);
    background-color: theme('colors.green.400');
    border-color: theme('colors.green.400');
  }
}

@keyframes revealPresentCharCell {
  0% {
    transform: rotateX(0deg);
    background-color: theme('colors.slate.400');
    border-color: theme('colors.slate.400');
  }
  100% {
    transform: rotateX(180deg);
    background-color: theme('colors.yellow.400');
    border-color: theme('colors.yellow.400');
  }
}

/* Additional animation on the child div to avoid letters turning upside down/snapping back to upright visual glitch */
@keyframes offsetLetterFlip {
  0% {
    transform: rotateX(0deg);
  }
  100% {
    transform: rotateX(180deg);
  }
}

@keyframes onTypeCell {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.4);
  }

  100% {
    transform: scale(1);
  }
}

.shadowed {
  text-shadow: 1px 1px 1px #000000;
}
html {
  background-color: #eee;
}
